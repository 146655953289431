<template>
  <v-container>
    <v-toolbar rounded class="mb-4">
      <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        label="Tour suchen..."
        v-model="searchInput"
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-checkbox
        v-model="showEventsWithoutPhotos"
        hide-details
        label="Zeige Touren ohne Fotos"
      ></v-checkbox>
    </v-toolbar>
    <v-row>
      <v-col
        xl="4"
        cols="12"
        sm="6"
        v-for="event in filteredEvents"
        :key="event.id"
      >
        <v-card>
          <template v-if="event.photos.length > 0">
            <v-img
              :src="`/media/${event.photos[0].uniqueName}?thumb=500x500`"
              height="180"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0, 0, 0, 0.5), rgba(0,0,0,.7)"
              class="white--text align-end"
            >
              <v-card-title>{{ event.title }}</v-card-title>
              <v-card-subtitle>
                <div>
                  {{ event.date }} &mdash; {{ getOrgInfo(event.organisation)?.name || 'Unbekannt'}}
                </div>
                <div>
                  {{ event.photos.length }} Fotos
                  {{
                    numUnpublished(event) > 0
                      ? `, ${numUnpublished(event)} unveröffentlicht`
                      : ""
                  }}
                </div>
              </v-card-subtitle>
            </v-img>
          </template>
          <template v-else>
            <v-card-title>{{ event.title }}</v-card-title>
            <v-card-subtitle>
              <div>
                {{ event.date }} &mdash; {{ getOrgInfo(event.organisation)?.name || 'Unbekannt' }}
              </div>
              <div>
                {{ event.photos.length }} Fotos
                {{
                  numUnpublished(event) > 0
                    ? `, ${numUnpublished(event)} unveröffentlicht`
                    : ""
                }}
              </div>
            </v-card-subtitle>
          </template>
          <v-row dense class="pt-2">
            <!-- Loop over the images -->
            <v-col
              v-for="(image, index) in event.photos.length > numPreview
                ? event.photos.slice(1, numPreview - 1)
                : event.photos.slice(1)"
              :key="index"
              class="d-flex child-flex"
              cols="3"
            >
              <v-img
                :src="`/media/${image.uniqueName}?thumb=500x500`"
                :lazy-src="`/media/${image.uniqueName}?thumb=10x10`"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <!-- "More images" indicator for events with more than numPreview images -->
            <v-col
              v-if="event.photos.length > numPreview"
              cols="3"
              class="d-flex child-flex align-center"
            >
              <v-sheet
                outlined
                class="
                  align-self-stretch
                  d-flex
                  justify-center
                  flex-column
                  text-center
                  font-weight-bold
                  text--secondary
                "
                style="font-size: 3vh"
                >+ {{ event.photos.length - numPreview + 1 }}
              </v-sheet>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              large
              :to="{ name: 'EventPhotos', params: { id: event.id } }"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex child-flex">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          @click="loadMore"
          :loading="loadingEvents"
          :disabled="loadingEvents || !nextPage"
        >
          Mehr Touren laden...
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import eventService from '@/services/event'
import { mapState } from 'pinia'
import { useEventStore } from '@/stores/event'


export default {
  components: {},
  name: 'Dashboard',
  props: {},
  data() {
    return {
      numPreview: 5,
      searchInput: '',
      events: [],
      nextPage: '',
      eventsWithPhotos: 0,
      minEventsWithPhotos: 2,
      loadEventsPerPage: 50,
      showEventsWithoutPhotos: false,
      loadingEvents: false,
    }
  },
  computed: {
    filteredEvents() {
      return this.events.filter(e => {
        if (!this.showEventsWithoutPhotos) {
          if (e.photos.length === 0) {
            return false
          }
        }
        if (this.searchInput) {
          return e.title.includes(this.searchInput)
        }
        return true
      })
    },
    ...mapState(useEventStore, { organisations: state => state.meta.organisations }),
  },
  methods: {
    numUnpublished(event) {
      return event.photos.length - event.photos.map(photo => photo.published ? 1 : 0).reduce((a, b) => a + b, 0)
    },
    getOrgInfo(organisation) {
      return this.organisations.find((o) => o.code === organisation)
    },
    fetchNextPage() {
      return eventService.listPage(this.nextPage).then(data => {
        this.nextPage = data.page_next
        this.processEventList(data.items)
      })
    },
    async loadMore() {
      this.loadingEvents = true
      const numEvents = this.eventsWithPhotos
      while (this.nextPage) {
        await this.fetchNextPage()
        if (this.eventsWithPhotos > numEvents + 4) {
          break
        }
      }
      this.loadingEvents = false
    },
    processEventList(events) {
      for (const event of events) {
        event.photos = []
        this.events.push(event)
        if (event.num_photos > 0) {
          this.eventsWithPhotos++
          eventService.getPhotos(event.id).then(photos => {
            event.photos = photos
          })
        }
      }
    },
  },
  mounted() {
    this.loadingEvents = true
    useEventStore().initialize()
    eventService.list({ only: 'past', sort: 'desc' }, this.loadEventsPerPage).then(response => {
      const data = response.data
      this.nextPage = data.page_next
      this.processEventList(data.items)

      if (this.eventsWithPhotos < this.minEventsWithPhotos && this.nextPage) {
        this.loadMore()
      } else {
        this.loadingEvents = false
      }
    })
  },
}

</script>
